/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Alert classes */

.addToClipAlert.alert-wrapper {
  --background: black!important;
}
.ion-padding-left {
  padding-left: 16px;
}

.ion-padding-right {
  padding-right: 16px;
}

ion-card.norestis {
  border: none;
  box-shadow: none!important;
  -webkit-box-shadow: none!important;

}

ion-item:hover {
  --ion-item-background: #f0f0f0;
  cursor: pointer;
}

img.placeholderpic {
  width: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

img.cardimg {
    width: 100%;
    max-width: 400px;
    max-height: 300px;
    padding: 0 10px;
    margin: 20px auto 0 auto;
  }
  
  .inactive_view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 999999;
    background-color: #dedede;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
  }
  
  .inactive_view_text {
    position: relative;
    top: 40%;
    margin: 0 auto;
    max-width: 200px;
    word-wrap: break-word;
    background-color: #3171e0;
    border-radius: 20px;
    padding: 5px;
    font-size: 0.8em;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }
  
  ion-icon.iconbggrey {
      background: #cccccc;
      border-radius: 50%;
      padding: 4px;
  }
  
  h1.h1medium{
    margin-top: 6px!important;
    font-size: 1.2em!important;
  }
  
  .alertBg {
    --background: #fff!important;
  }
  
  ion-menu ion-label {
    font-weight: bold;
  }
  
  ion-menu .itemdesc {
    font-weight: normal!important;
  }
  
  
  /* hide scrollbar */
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  .hide_tab .tabbar {
    display: none;
  }
  
  .commonbg {
    background: #F3F3F9 !important;
  }
  
  .test-modal {
    height: 120px;
    width: 200px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;
    box-shadow: 0 16px 20px rgba(0, 0, 0, .4);
    position: absolute;
    overflow: hidden;
    border-radius: 5px !important;
    
  }
  
  .no-padding {
    padding: 0!important;
  }
  
  .no-margin {
    margin: 0!important;;
  }
  
  .no-border {
    border: 0!important;
    --border: 0!important;
    --border-width: 0!important;
    border-style: none!important;
  }
  
  .ion-no-padding-top {
    padding-top: 0!important;
  }
  
  .roundbg {
    background: #f4f4f4!important;
    padding-top: 10px;
    border-radius: 40px 40px 0 0;
  }
  
  .bordertopbottom {
    border-top: solid 0.55px lightgrey;
    border-bottom: solid 0.55px lightgrey;
  }
  
  .borderbottom {
    border-bottom: solid 0.55px lightgrey;
  }
  
  .bordertop {
    border-top: solid 0.55px lightgrey;
  }
  
  .gardientbg {
    background: rgb(244,244,244);
    background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(255,255,255,1) 35%, rgba(244,244,244,1) 100%);
  }
  
  .shadow_down {
    -webkit-box-shadow: 0px 7px 18px -9px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 7px 18px -9px rgba(0,0,0,0.20);
    box-shadow: 0px 7px 18px -9px rgba(0,0,0,0.20);
  }
  
  .shadow_top {
    -webkit-box-shadow: 0px -7px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px -7px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px -7px 10px 0px rgba(0,0,0,0.2);
  }
  /*ion-header::after {
    display: none;
  }*/
  
  html[mode="ios"] { height: 100vh; }
  
  /* Circle Loader Start */
  
  .loading-content {
    text-align: center;
  }
  
  // Define vars we'll be using
  $brand-success: #5cb85c;
  $loader-size: 100px;
  $check-height: $loader-size/2;
  $check-width: $check-height/2;
  $check-left: ($loader-size/6 + $loader-size/12);
  $check-thickness: 3px;
  $check-color: $brand-success;
  
  .disp {
    display: block!important;
  }
  .circle-loader {
  margin-left: auto;
  margin-right: auto; 
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
  }
  
  .load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 1000ms ease-out;
  }
  
  .checkmark {
  display: none;
  
  &.draw:after {
    animation-duration: 1200ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  
  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
  }
  
  @keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  }
  
  @keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  }
  
  /* Circle Load End*/
  
  ion-modal.add-to-cart-popup {
    @media (min-width: 300px) and (min-height: 480px) {
      ion-backdrop {
        visibility: visible;
      }
    }
  
    .modal-wrapper {
      position: absolute;
      overflow: hidden;
      border-radius: 2px;
      width: 100%;
      //top: 50%;
      //top: 300px;
      height: 70%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border: 0;
      box-shadow: 0 16px 20px rgba(0, 0, 0, .4);
      background-color: #fafafa;
    }
  }